<template>
  <!-- Header Start -->
  <q-header
    elevated
    height-hint="70"
    :class="{
      betaEnabledBtn:
        (isBetaModeActive || isTestModeActive) &&
        isBetaAccessCodeValidated &&
        $route.name == 'Home',
    }"
  >
    <!-- :reveal="isMobile && $route.name == 'Home'" -->
    <ModeHeader
      v-show="
        (isBetaModeActive || isTestModeActive) &&
        isBetaAccessCodeValidated &&
        (!isMobile || (isMobile && $route.name == 'Home'))
      "
      :mode="activeMode"
    />
    <q-toolbar class="header">
      <!-- Navigation and Site Logo Begin -->
      <div
        class="col-6 col-sm-8 h-left"
        v-show="header.logo || header.categoryButton"
      >
        <!-- category toggle button -->
        <q-toolbar>
          <q-btn
            flat
            dense
            @click="
              () => {
                if (this.currentLocationId)
                  this.changeDialogState({
                    dialog: 'categoryDrawer',
                    val: !this.categoryDrawer,
                  })
              }
            "
            v-show="header.categoryButton"
          >
            <SvgIcon
              color="white"
              className="q-ml-sm q-mr-sm"
              size="1rem"
              icon="sidebar-toggle"
            />
          </q-btn>
          <Logo v-show="header.logo" />
        </q-toolbar>
      </div>
      <div
        class="col-8 col-sm-8 h-left"
        v-bind:class="{ bigwidth: !header.changeLocationButton }"
        v-show="header.backButton"
      >
        <!-- category toggle button -->
        <q-toolbar>
          <q-btn flat dense @click="applyBack()" v-if="header.backButton">
            <SvgIcon
              color="white"
              className="q-ml-xs q-mr-xs"
              size="1rem"
              icon="back-arrow-line"
            />
          </q-btn>

          <q-btn
            flat
            dense
            :to="{
              name: 'Home',
            }"
            v-if="header.homeButtom"
          >
            <SvgIcon
              color="white"
              className="q-mx-xs"
              size="1rem"
              icon="home"
            />
          </q-btn>

          <q-toolbar-title>
            <template v-if="header.title">
              {{ $options.filters.titleCase(header.title) }}
            </template>
            <q-skeleton v-else height="20px" />
          </q-toolbar-title>
        </q-toolbar>
      </div>
      <!-- Navigation and Site Logo End -->

      <!-- location -->
      <div
        class="col-6 col-md-auto h-location relative-position"
        v-show="header.changeLocationButton"
      >
        <!-- @click="this.openLocationDialog" -->
        <div
          class="relative-position"
          v-if="currentLocationId"
          @click="openLocationDialog"
        >
          <SvgIcon class="q-pr-sm" color="white" size="1rem" icon="location" />
          <span class="h-location-selected">
            <span class="h-location-title">{{ deliveryTitle }}</span>
            <span class="h-location-value ellipsis text-capitalize">
              {{ locationDeliveryInfo }}
              <q-tooltip
                v-if="!$q.screen.lt.md"
                content-class="bg-dark text-capitalize"
                :offset="[10, 10]"
              >
                {{ locationDeliveryInfo }}
              </q-tooltip>
            </span>
          </span>
          <SvgIcon
            className="q-ml-sm"
            color="white"
            size=".6rem"
            icon="arrow-down"
          />
        </div>
        <template v-if="currentLocationId">
          <LocationPopover v-if="!isMobile" />
          <LocationDialog v-if="isMobile" />
          <!-- <SelectAddressDialog /> -->
          <ChangeStoreDialog />
        </template>
      </div>
      <!-- location end-->

      <div class="col-12 col-md h-search" v-show="header.searchBar">
        <div class="search_bar">
          <div class="row q-ml-md q-mr-md">
            <q-btn
              flat
              dense
              no-caps
              class="h-search--all q-pl-sm q-pr-sm"
              :title="$options.filters.titleCase(searchCategory.categoryName)"
              :label="$options.filters.titleCase(selectedSearchCategoryLabel)"
              color="grey-2"
              text-color="grey-8"
              :ripple="false"
              @click="
                changeDialogState({
                  dialog: 'selectCategory',
                  val: true,
                })
              "
            >
              <SvgIcon
                className="q-ml-sm"
                size=".8rem"
                icon="dark-arrow-down"
              />
              <template v-if="currentLocationId">
                <CategorySelectDialog v-if="isMobile" />
                <CategorySelectPopup v-else />
              </template>
            </q-btn>

            <SearchBar />
            <SearchWithList />
          </div>
        </div>
      </div>

      <!-- Reserve Time Slot -->
      <div
        class="col-6 col-md-auto h-quicklinks flex justify-end items-center"
        v-show="header.reserveTimeSlotButton"
        :class="{
          offerdealbtn_show: header.offerDealsButton,
        }"
      >
        <div class="col-auto text-center h-announcement" v-if="isMobile">
          <q-btn
            v-show="showAnnouncementButton"
            dense
            flat
            color="white"
            padding="8px 10px"
            @click="
              changeDialogState({
                dialog: 'AnnouncementDialog',
                val: true,
              })
            "
          >
            <div class="icon">
              <span v-if="!announcementsRead" class="counter"></span>
              <q-icon :size="isMobile ? '1.3rem' : '1rem'" name="campaign" />
            </div>
          </q-btn>
        </div>
        <div
          :class="['col-auto h-reserve', isSlotBookingRequired ? '' : 'hidden']"
          @click="openReserveTimeslot"
        >
          <q-btn
            dense
            flat
            color="white"
            @click="openReserveTimeslot"
            class="h-timeslot q-btn-text"
          >
            <SvgIcon
              flat
              dense
              className="button-icon"
              color="white"
              :size="isMobile ? '1.1rem' : '1rem'"
              icon="calendar-white"
            />
            <span class="button-text" v-if="!isMobile"
              ><small>Reserve</small>Time Slot</span
            >
          </q-btn>
        </div>
      </div>

      <div
        class="col-sm-4 col-md-2 h-right"
        :class="[
          header.backButton || isMobile ? 'col-4' : 'col-6',
          $route.name == 'Home' && isMobile ? 'hidden' : '',
        ]"
        v-show="
          header.accountButton || header.favoriteButton || header.cartButton
        "
      >
        <q-toolbar
          class="justify-end"
          :class="header.backButton || isMobile ? 'no-flex-wrap' : ''"
        >
          <q-btn
            v-show="showAnnouncementButton"
            flat
            color="white"
            class="h-announcement q-btn-text"
            @click="
              changeDialogState({
                dialog: 'AnnouncementDialog',
                val: true,
              })
            "
          >
            <div class="icon">
              <span v-if="!announcementsRead" class="counter"></span>
              <q-icon name="campaign" class="button-icon" />
            </div>
            <span class="button-text" v-if="!isMobile">
              <small>Stay Tuned!</small>Announcements
            </span>
          </q-btn>
          <q-btn
            flat
            v-show="header.searchButton"
            @click="
              changeDialogState({
                dialog: 'MobileSearchDialog',
                val: true,
              })
            "
          >
            <SvgIcon icon="simple-search" size="1.4em" />
          </q-btn>
          <!-- Loyalty button -->
          <q-btn
            v-if="!$q.screen.lt.md && storeHasLnsSubscription"
            :to="isLoggedIn ? '/loyalty-program#/' : null"
            flat
            @click="
              !isLoggedIn &&
                changeDialogState({
                  dialog: 'accountDrawer',
                  val: true,
                })
            "
            class="h-loyalty q-btn-text"
          >
            <SvgIcon
              color="white"
              size="1.4em"
              icon="loyalty-o"
              className="button-icon"
            />
            <span class="button-text" v-if="!isMobile"
              ><small>Loyalty</small>Program</span
            >
          </q-btn>
          <!-- Loyalty button -->
          <!-- Heart button -->
          <q-btn
            class="h-wishlist q-btn-text"
            flat
            :to="{ name: 'Favorites' }"
            v-show="header.favoriteButton"
          >
            <SvgIcon
              flat
              dense
              color="white"
              size="20px"
              icon="heart"
              className="button-icon"
            />
            <span class="button-text" v-if="!isMobile"
              ><small>Your</small>Favorites</span
            >
          </q-btn>
          <!-- Account button -->
          <q-btn
            class="h-account q-btn-text"
            flat
            @click="
              changeDialogState({
                dialog: 'accountDrawer',
                val: !getDialogState('accountDrawer'),
              })
            "
            v-show="header.accountButton"
          >
            <template v-if="isLoggedIn">
              <span class="button-icon">
                <UserAvatar />
                <q-badge
                  v-if="notificationcount"
                  rounded
                  floating
                  class="useravatar-notification-count"
                  :label="notificationcount < 100 ? notificationcount : '99+'"
                />
              </span>
              <span class="button-text max-w60 ellipsis" v-if="!isMobile"
                ><small>Account</small>{{ currentUserName }}</span
              >
            </template>
            <template v-else>
              <SvgIcon
                color="white"
                size="1.4em"
                icon="avtar"
                className="button-icon"
              />
              <span class="button-text" v-if="!isMobile"
                ><small>Sign In</small>Account</span
              >
            </template>
          </q-btn>

          <!-- Cart button -->
          <q-btn
            flat
            class="h-cart shopping-cart q-btn-text"
            @click="cartClicked"
            v-show="header.cartButton"
          >
            <SvgIcon
              flat
              dense
              color="white"
              icon="cart"
              :size="isMobile ? '1.5em' : ''"
              className="button-icon"
            />
            <span
              class="itemCount"
              v-show="cartTotalItems"
              v-text="cartTotalItems < 100 ? cartTotalItems : '99+'"
            />
            <span class="button-text" v-if="!isMobile"
              ><small>Your Cart</small>{{ getSubCartTotal | kCurrency }}</span
            >
          </q-btn>
        </q-toolbar>
      </div>
    </q-toolbar>
  </q-header>
  <!-- Header End -->
</template>

<script>
// import LocationHeader from './LocationHeader'

import { mapGetters } from 'vuex'
import Logo from './Logo'
import ModeHeader from './ModeHeader'
import SearchBar from './SearchBar'
import SearchWithList from './SearchWithList'

export default {
  name: 'Header',
  components: {
    // LocationHeader,
    Logo,
    SearchBar,
    SearchWithList,
    ModeHeader,
    // lazy load
    CategorySelectPopup: () => import('./CategorySelectPopup'),
    CategorySelectDialog: () => import('./CategorySelectDialog'),
    LocationPopover: () => import('./LocationPopover'),
    LocationDialog: () => import('./LocationDialog'),
    ChangeStoreDialog: () => import('./ChangeStoreDialog'),
    // SelectAddressDialog: () => import('./SelectAddressDialog'),
  },
  data() {
    return {
      dataMapper: {
        sp: 'Pickup From',
        scd: 'Delivery From',
        sd: 'Ship To',
      },
    }
  },
  computed: {
    ...mapGetters('persisted', [
      'isBetaModeActive',
      'isTestModeActive',
      'activeMode',
      'isBetaAccessCodeValidated',
      'eaivPersistedVerificationSuccess',
    ]),
    ...mapGetters('persistedLocal', [
      'announcementUpdate',
      'announcementsRead',
    ]),
    ...mapGetters('customer', ['customer']),
    categoryDrawer() {
      return this.getDialogState('categoryDrawer')
    },
    DisclaimerDialog() {
      return this.getDialogState('DisclaimerDialog')
    },
    askForSignupDialog() {
      return this.getDialogState('AskForSignup')
    },
    deliveryTitle() {
      return this.dataMapper[this.selectedFulfillment?.codeName] || ''
    },
    locationDeliveryInfo() {
      if (this.selectedFulfillment.codeName == 'sd') {
        if (this.selectedZipCode) return this.selectedZipCode
        else return this.defaultAddress?.zip || ''
      }

      return this.currentLocationName
    },
    locationDialogState: {
      get() {
        return this.getDialogState('location')
      },
    },
    selectedSearchCategoryLabel() {
      let allowedChars = this.isMobile ? 3 : 8

      return this.selectedSearchCategory.length > allowedChars
        ? this.selectedSearchCategory.substring(0, 3) + '...'
        : this.selectedSearchCategory
    },
    selectedSearchCategory() {
      return this.searchCategory?.categoryName || ''
    },
    ...mapGetters('category', ['searchCategory']),
    ...mapGetters('common', [
      'header',
      'isSlotBookingRequired',
      'storeHasLnsSubscription',
      'clientStoreConfigurationLoaded',
    ]),
    ...mapGetters('cart', ['getSubCartTotal', 'cartTotalItems']),
    ...mapGetters('persisted', ['selectedZipCode']),
    ...mapGetters('notification', ['notificationcount']),
    ...mapGetters('address', ['defaultAddress']),
    modeDescription() {
      return (
        (this.businessConfigurations &&
          this.businessConfigurations?.modeDescription) ||
        ''
      )
    },
    announcements() {
      return this.commonStateByKey('announcements') || []
    },
    showAnnouncementButton() {
      return this.announcements.length
    },
  },
  methods: {
    cartClicked() {
      if (this.isMobile)
        this.$router
          .push({
            name: 'Cart',
          })
          .catch(() => {})
      else
        this.changeDialogState({
          dialog: 'cartDrawer',
          val: !this.getDialogState('cartDrawer'),
        })
    },
    openReserveTimeslot() {
      let openDialog = () => {
        this.changeDialogState({
          dialog: 'reserveTimeslot',
          properties: {
            selectedFromReserveSlot: false,
          },
          val: true,
        })
      }

      // if (!this.isLoggedIn) return this.loginWithIdentity()
      if (this.isLoggedIn && !this.customerExists)
        return this.signUpConfirm().onOk(() => {
          openDialog()
        })

      if (
        this.isLoggedIn ||
        (!this.isLoggedIn && this.businessConfigurations?.enableGuestCheckout)
      ) {
        openDialog()
      } else {
        if (!this.isLoggedIn)
          this.changeDialogState({
            dialog: 'accountDrawer',
            val: true,
          })
      }
    },
    openLocationDialog() {
      if (this.isMobile) {
        this.changeDialogState({
          dialog: 'location',
          val: !this.locationDialogState,
        })
      }
    },
  },
}
</script>

<style lang="scss">
.h-announcement {
  .icon {
    position: relative;
  }
  .counter {
    display: flex;
    width: 9px;
    height: 9px;
    background: red;
    border: 1px solid #fff;
    position: absolute;
    top: 0;
    right: -2px;
    animation: blink 1.5s infinite ease-in-out;
    z-index: 1;
    overflow: hidden;
    border-radius: 5px;
  }
}
@keyframes blink {
  0%,
  20%,
  100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

.h-location-title {
  font-size: 14px;
  border-right: 2px solid #fff;
  padding-right: 9px;
  line-height: 10px;
  display: inline-block;
  margin-right: 5px;
}
.h-location-value {
  font-size: 12px;
}
.q-header {
  transition: all 0.3s ease-in-out;
  @media (max-width: 1300px) {
    .header-label {
      display: none !important;
    }
  }
  .header {
    min-height: 58px;
    .headerlogosvg {
      width: auto;
      height: auto;
      max-width: 110px;
    }
  }
  .h-location {
    cursor: pointer;
    font-size: 14px;
    @media (min-width: 1921px) {
      font-size: 18px;
    }
    > .relative-position {
      padding-right: 20px;
      @media (min-width: 1024px) {
        text-align: right;
      }
      @media (max-width: 767px) {
        padding-right: 0;
        .h-location-title {
          font-size: 12px;
          padding-right: 8px;
          margin-right: 8px;
          vertical-align: middle;
        }
        .h-location-value {
          display: inline-block;
          max-width: 90px;
          vertical-align: middle;
        }
      }
      @media (max-width: 360px) {
        .h-location-value {
          max-width: 60px;
        }
      }
    }
    [data-icon='arrow-down'] {
      @media (min-width: 1024px) {
        position: absolute;
        right: 0;
        margin-top: 5px;
      }
    }
  }
  .header .q-toolbar__title {
    font-size: 16px;
    @media (max-width: 767px) {
      padding-left: 0px;
    }
  }
  @media (max-width: 1023px) {
    .h-location {
      order: 5;
      flex: 1 0 50%;
      padding: 10px 15px;
      position: relative;
      &:before {
        content: '';
        background: $primary;
        background: var(--q-color-primary) !important;
        filter: brightness(80%);
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        position: absolute;
      }
    }
  }
  @media (max-width: 599px) {
    .h-location {
      flex: 1 0 60%;
      font-size: 11px;
      min-height: 40px;
      padding-top: 13px;
    }
  }
}

.q-header {
  background: $primary;
  background: var(--q-color-primary) !important;
  .q-layout__shadow {
    opacity: 0.6;
  }
  .q-focus-helper,
  .q-ripple {
    display: none;
  }
  .header {
    background: $primary;
    background: var(--q-color-primary) !important;
    padding: 5px 0;
    @media (min-width: 1921px) {
      padding: 15px 0;
    }
    @media (max-width: 1023px) {
      padding: 0;
    }
  }
  .h-left {
    .q-btn__wrapper:before {
      border-color: transparent;
    }
  }
  .h-reserve {
    cursor: pointer;
    font-size: 14px;
    @media (min-width: 1921px) {
      font-size: 20px;
    }
  }
  .h-search--all {
    font-size: 15px;
    background: #eeeeee;
    @media (min-width: 1921px) {
      font-size: 20px;
    }
  }
  .h-search {
    .search_bar {
      @media (min-width: 1921px) {
        .q-field--dense .q-field__control,
        .q-field--dense .q-field__marginal {
          height: 60px;
          .q-btn {
          }
        }
      }
    }
    label {
      padding-bottom: 0;
    }
  }
  .h-discount {
    margin-left: 15px;
    i {
      font-size: 18px;
    }
    @media (max-width: 1023px) {
      position: absolute;
      right: 15px;
      bottom: 10px;
      width: 25px;
      height: 20px;
    }
  }
  .h-right {
    .shopping-cart {
      .q-btn__content {
        position: relative;
        .itemCount {
          position: absolute;
          left: 4px;
          bottom: -6px;
          display: block;
          font-size: 9px;
          background: #f32626;
          color: #fff !important;
          width: 16px;
          height: 16px;
          line-height: 17px;
          text-align: center;
          border-radius: 50%;
          box-shadow: none;
        }
      }
      @media (max-width: 767px) {
        .q-btn__wrapper {
          padding-top: 2px;
        }
      }
    }
    .useravatar-notification-count {
      border: 1px solid var(--q-color-secondary);
      background-color: #fff;
      min-width: 17px;
      height: 17px;
      color: var(--q-color-secondary);
      text-align: center;
      padding: 0 2px;
      display: block;
      line-height: 15px;
      font-size: 10px;
      font-weight: bold;
      top: 0;
      right: 0;
    }
  }
  .h-right .q-btn__wrapper {
    padding-left: 12px;
    padding-right: 12px;
    @media (max-width: 767px) {
      padding-left: 8px;
      padding-right: 8px;
    }
  }
  @media (max-width: 1023px) {
    .row {
      flex-wrap: wrap;
    }
    .h-left {
      width: 13%;
      max-width: 50px;
      &.bigwidth {
        width: 66.6667%;
        max-width: 100%;
        @media (max-width: 575px) {
          width: 46.6667%;
        }
        .q-toolbar__title {
          display: inline-flex;
          @media (max-width: 575px) {
            display: block;
          }
        }
      }
      .q-toolbar {
        padding-right: 0;
      }
      .q-toolbar__title {
        display: none;
      }
    }
    .h-search {
      order: 3;
      flex: 1 0 87%;
      margin-top: 10px;
      margin-bottom: 10px;
    }
    .h-quicklinks {
      order: 6;
      flex: 1 0 50%;
      padding: 0px 12px;
      position: relative;
      &:before {
        content: '';
        background: $primary;
        background: var(--q-color-primary) !important;
        filter: brightness(80%);
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        position: absolute;
      }
      @media (max-width: 1023px) {
        &.offerdealbtn_show {
          padding-right: 50px;
        }
      }
    }
    .h-reserve,
    .h-right {
      text-align: right;
    }
  }
  @media (max-width: 599px) {
    .h-quicklinks {
      flex: 1 0 20%;
      min-height: 40px;
    }
  }
}
.h-left {
  @media (max-width: 1023px) {
    padding-bottom: 5px;
    padding-top: 5px;
  }
}
@media (min-width: 1024px) {
  .h-left {
    height: auto !important;
    width: 270px !important;
    .q-toolbar {
      min-height: 60px;
      padding-right: 5px;
    }
    .q-toolbar__title {
      padding-top: 2px;
      padding-bottom: 2px;
    }
  }
  .h-right {
    height: auto !important;
    width: 340px !important;
  }
}

@media (min-width: 1024px) {
  .h-location {
    height: auto !important;
    width: 200px !important;
  }
}
@media (min-width: 1376px) {
  .h-location {
    height: auto !important;
  }
}
@media (min-width: 1921px) {
  .h-right {
    height: auto !important;
    width: 345px !important;
  }
}
@media (max-width: 599px) {
  .h-location,
  .h-quicklinks {
    transition: all 0.2s ease-in-out;
  }
  .scrollDown .h-location,
  .scrollDown .h-quicklinks {
    opacity: 0;
    min-height: 0px !important;
    height: 0px !important;
    visibility: hidden;
    overflow: hidden;
    padding: 0px 15px !important;
  }
  .no-flex-wrap {
    flex-wrap: unset !important;
  }
}

@media (min-width: 1024px) {
  .h-right {
    width: auto !important;
    .q-toolbar {
      padding-left: 0;
    }
  }
  .h-location {
    width: 120px !important;
    & > .relative-position {
      display: flex;
      flex-direction: row;
      align-items: center;
    }
    .h-location-selected {
      text-align: left;
      min-width: 0;
      line-height: normal;
      & ~ .q-icon {
        margin-top: 0;
      }
    }
    .h-location-title {
      border: none;
      font-size: 11px;
      display: block;
      padding-right: 0;
    }
    .h-location-value {
      font-size: 14px;
      display: block;
      padding-top: 2px;
    }
  }
  .h-reserve {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    .button-text {
      text-align: left;
      line-height: normal;
      text-transform: none;
      margin-left: 8px;
      font-size: 14px;
    }
    small {
      display: block;
      font-size: 11px;
    }
  }
  .q-btn-text {
    .q-btn__wrapper {
      padding-left: 8px !important;
      padding-right: 8px !important;
    }
    .q-btn__content {
      .button-icon {
        position: relative;
        .useravatar-notification-count {
          top: 20px;
          left: calc(50% + 1px);
          transform: translateX(-50%);
        }
      }
      .button-text {
        text-align: left;
        line-height: normal;
        text-transform: none;
        margin-left: 8px;
        font-size: 13px;
      }
      small {
        display: block;
        font-size: 11px;
      }
    }
    &:hover {
      position: relative;
      .q-focus-helper {
        display: block;
        filter: brightness(1%);
        -webkit-border-radius: 6px;
        border-radius: 6px;
        @media (min-width: 1440px) {
          width: calc(100% + 10px);
          height: calc(100% + 6px);
          left: -5px;
          top: -3px;
        }
      }
    }
  }
  .q-header .search_bar > .q-mr-md {
    margin-right: 8px;
  }
}

@media (min-width: 1440px) {
  .q-btn-text {
    margin: 0 6px;
  }
}

@media (min-width: 1024px) and (max-width: 1279px) {
  .h-timeslot,
  .h-announcement,
  .h-loyalty,
  .h-wishlist {
    .button-text {
      display: none;
    }
  }
}

@media (min-width: 1024px) and (max-width: 1439px) {
  .q-header .h-search--all {
    font-size: 12px;
  }
  .h-search--all .q-icon {
    font-size: 0.6rem;
  }
  .h-search .q-field__native {
    padding-right: 0;
  }
  .h-search-input .q-field__append {
    padding-left: 0;
  }
  .h-search--list {
    padding: 0;
  }
}

@media (min-width: 1024px) and (max-width: 1600px) {
  .h-search .h-search-dropdown {
    width: 640px;
    left: 50%;
    border-radius: 5px;
    top: calc(100% + 4px);
    margin-left: -328px;
  }
  .h-search .h-search-dropdown .label {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }
}
</style>
