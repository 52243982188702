import { render, staticRenderFns } from "./NotificationList.vue?vue&type=template&id=269778e0"
import script from "./NotificationList.vue?vue&type=script&lang=js"
export * from "./NotificationList.vue?vue&type=script&lang=js"
import style0 from "./NotificationList.vue?vue&type=style&index=0&id=269778e0&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import QCard from 'quasar/src/components/card/QCard.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QSeparator from 'quasar/src/components/separator/QSeparator.js';
import QScrollArea from 'quasar/src/components/scroll-area/QScrollArea.js';
import QSpinner from 'quasar/src/components/spinner/QSpinner.js';
import QList from 'quasar/src/components/item/QList.js';
import qInstall from "../../../../node_modules/@quasar/app/lib/webpack/runtime.auto-import.js";
qInstall(component, 'components', {QCard,QCardSection,QBtn,QIcon,QSeparator,QScrollArea,QSpinner,QList});
