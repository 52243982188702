<template>
  <!-- Sidebar Account Begin -->
  <q-drawer
    class="sidebarAccount"
    v-model="drawerState"
    side="right"
    bordered
    overlay
    content-class="bg-white"
    :width="width"
    @before-hide="closeNotificationDrawer"
    :no-swipe-backdrop="isPWA"
    :no-swipe-open="isPWA"
    :no-swipe-close="isPWA"
  >
    <div class="sidebarHeader">
      <div class="row items-center">
        <div class="col ellipsis"> </div>
        <div class="col-auto">
          <q-btn dense flat @click="closeAccount">
            <q-icon name="close" />
          </q-btn>
        </div>
      </div>
    </div>

    <!-- After Login Screen -->
    <div v-if="isLoggedIn">
      <div class="accountUserInfo">
        <q-item class="item-start">
          <q-item-section avatar class="items-center">
            <UserAvatar font-size="24px" />
          </q-item-section>

          <q-item-section>
            <q-item-label class="text-h5 ellipsis">
              Hey, {{ currentUserName }}
            </q-item-label>
            <q-item-label
              class="ellipsis"
              caption
              v-text="currentUserIdentity"
            />
          </q-item-section>
        </q-item>
        <q-item class="butttons q-pa-none">
          <q-item-section class="q-pa-none">
            <div class="row q-col-gutter-sm">
              <div class="col-6">
                <template v-if="!customer.isVerifiedOnline">
                  <div
                    class="btn-badge flex items-center q-mb-sm cursor-pointer"
                    @click="triggerEAIVFlow"
                  >
                    <SvgIcon
                      size="30px"
                      icon="age-verifys"
                      class="q-mr-sm"
                    />Age not verified
                    <q-tooltip
                      anchor="top middle"
                      self="bottom middle"
                      :offset="[10, 10]"
                      content-class="bg-grey-10"
                      content-style="font-size: 12px"
                      >Click here to verify your age</q-tooltip
                    >
                  </div>
                </template>
                <template v-else>
                  <div
                    class="btn-badge badge-verified flex items-center q-mb-sm"
                  >
                    <SvgIcon
                      size="30px"
                      icon="age-verified"
                      class="q-mr-sm"
                    />Age Verified
                  </div>
                </template>
              </div>
              <div class="col-6">
                <template v-if="!customer.isAVTVerified">
                  <div class="btn-badge flex items-center q-mb-sm">
                    <SvgIcon size="30px" icon="avt-verify" class="q-mr-sm" />AVT
                    not verified
                  </div>
                </template>
                <template v-else>
                  <div
                    class="btn-badge badge-verified flex items-center q-mb-sm"
                  >
                    <SvgIcon
                      size="30px"
                      icon="avt-verified"
                      class="q-mr-sm"
                      v-if="customer.isAVTVerified"
                    />
                    AVT Verified
                  </div>
                </template>
              </div>
              <div
                class="col-12"
                v-if="
                  customer.isVerifiedOnline &&
                  businessConfigurations.isStoreAgeRestricted &&
                  businessConfigurations.enableAgeRestrictedFulfillment
                "
              >
                <q-btn
                  unelevated
                  no-caps
                  flat
                  color="tertiary"
                  label="Show age-verified QR"
                  class="text-underline no-hover text-caption self-start"
                  padding="0"
                  @click="openAgeVerifiedQR"
                />
              </div>
              <div class="col-4">
                <q-btn
                  unelevated
                  no-caps
                  color="secondaryOnBody"
                  stack
                  icon="notifications"
                  class="full-width"
                  @click="openNotificationDrawer"
                >
                  Notification
                  <span class="notification-btn-count absolute">{{
                    notificationcount
                  }}</span>
                </q-btn>
              </div>
              <div class="col-4">
                <ProfileLink :fullBtn="false" />
              </div>
              <div class="col-4">
                <q-btn
                  unelevated
                  no-caps
                  outline
                  color="secondaryOnBody"
                  stack
                  icon="logout"
                  class="full-width"
                  @click="performLogout"
                  :loading="logoutInitiated"
                >
                  Sign Out
                </q-btn>
              </div>
            </div>
            <NotificationList
              :notificationDrawer="notificationDrawer"
              @openNotificationDrawer="openNotificationDrawer"
            />
          </q-item-section>
        </q-item>
      </div>
      <div class="accountQuickLinks">
        <q-scroll-area
          class="accountLoggedHeight"
          :class="
            customer.isVerifiedOnline &&
            businessConfigurations.isStoreAgeRestricted &&
            businessConfigurations.enableAgeRestrictedFulfillment
              ? 'accountAgeVerifiedHeight'
              : ''
          "
          :thumb-style="scrollAreaThumbStyle"
          :bar-style="scrollAreaBarStyle"
          :visible="scrollAreaVisible"
          style="max-width: 100%"
        >
          <q-list>
            <q-card class="my-card" flat>
              <q-item
                v-for="(navLink, index) in navLinksArr"
                :key="`${navLink.title}-${index}`"
                :to="!navLink.external ? navLink.link : ''"
                clickable
                manual-focus
                class="item-start"
                @click="navLinkClicked(navLink)"
              >
                <q-item-section avatar>
                  <SvgIcon
                    size="1rem"
                    :icon="navLink.icon"
                    :class="navLink.icon"
                  />
                </q-item-section>

                <q-item-section>
                  <q-item-label class="title ellipsis" v-html="navLink.title" />
                  <!-- <q-item-label
                    class="items-center"
                    caption
                    v-html="navLink.description"
                  /> -->
                </q-item-section>
              </q-item>
            </q-card>
          </q-list>
        </q-scroll-area>
      </div>
    </div>

    <!-- Before Login Screen -->
    <div v-else class="sidebarPanel text-center">
      <q-scroll-area
        class="accountLoginHeight"
        :thumb-style="scrollAreaThumbStyle"
        :bar-style="scrollAreaBarStyle"
        :visible="scrollAreaVisible"
        style="max-width: 100%"
      >
        <q-img
          src="images/account/login-account.png"
          style="max-width: 125px"
        />

        <div class="q-px-md">
          <div class="row">
            <div class="col-xs-12">
              <h5>Sign in to your account</h5>
              <p>
                Sign in to Place Orders, access account information, and view or
                change settings. View all pending orders, past transactions,
                favorited items, and more by authenticating your account
                credentials.
              </p>
            </div>
            <div class="col-xs-12">
              <div class="form-action">
                <q-btn
                  no-caps
                  rounded
                  unelevated
                  color="secondaryOnBody"
                  label="Sign In"
                  @click="login(false)"
                  class="q-mt-sm full-width q-btn-lg"
                  v-close-popup
                />
              </div>
            </div>
            <div class="col-xs-12">
              <div class="form-action">
                <q-btn
                  no-caps
                  rounded
                  outline
                  unelevated
                  color="dark"
                  label="Sign Up For New Account"
                  class="full-width q-btn-lg"
                  @click="login(true, ' aat:signup')"
                  v-close-popup
                />
              </div>
            </div>
            <div class="col-xs-12">
              <div class="form-action">
                <q-btn
                  no-caps
                  rounded
                  unelevated
                  color="tertiary"
                  label="Sign Up Using Existing Account *"
                  class="full-width q-btn-lg"
                  @click="login(true, ' aat:signup sat:CompleteRegistration')"
                  v-close-popup
                />
              </div>
            </div>
            <div class="col-xs-12">
              <div
                class="text-subtitle2 text-grey-7 line-height-15 text-center"
              >
                * Please use this option if you have already registered inside
                the store and never created a login. It will associate all
                benefits you may have earned in the past with your newly created
                login here. you already registered in the store, you can sign up
                using that account for the E-Commerce site.
              </div>
            </div>
          </div>
        </div>
      </q-scroll-area>
    </div>
  </q-drawer>
  <!-- Sidebar Account Begin -->
</template>
<script>
// import { loyalNSaveLink } from 'src/constants'
import { hideShowFullSpinner } from 'src/utils'
import IdentityMixin from 'src/utils/IdentityMixin'
import { mapGetters } from 'vuex'
import NotificationList from './common/notification/NotificationList.vue'

const navLinks = [
  {
    title: 'Account',
    id: 'Account',
    icon: 'account-user',
    link: { name: 'Account' },
    description: '',
  },
  {
    title: 'Orders',
    id: 'Orders',
    icon: 'account-order',
    link: { name: 'Orders' },
    description: '',
  },
  {
    title: 'Manage My Subscription',
    id: 'auto-ship-orders',
    icon: 'account-autoship',
    link: { name: 'ManageSubscription' },
    description: '',
  },
  {
    title: 'Payment Methods',
    id: 'PaymentMethod',
    icon: 'account-card',
    link: { name: 'PaymentMethod' },
    description: '',
  },
  {
    title: 'Manage Address',
    id: 'Address',
    icon: 'account-address',
    link: { name: 'Address' },
    description: '',
  },
  {
    title: 'Favorites',
    id: 'Favorites',
    icon: 'account-heart',
    link: { name: 'Favorites' },
    description: '',
  },
  {
    title: 'Wishlist',
    id: 'Wishlist',
    icon: 'wishlist',
    link: { name: 'ManageWishlist' },
    description: '',
  },
  {
    title: 'Reviews',
    id: 'Reviews',
    icon: 'account-review',
    link: { name: 'Reviews' },
    description: '',
  },
  /* {
    title: 'Loyal~N~Save',
    id: 'LNS',
    icon: 'lns-logo',
    external: true,
    link: loyalNSaveLink,
    description: '',
  }, */
]

export default {
  mixins: [IdentityMixin],
  computed: {
    drawerState: {
      get() {
        return this.getDialogState('accountDrawer')
      },
      set(val) {
        if (val != this.drawerState)
          this.changeDialogState({
            dialog: 'accountDrawer',
            val,
          })

        this.authRedirectUri &&
          !val &&
          this.$store.commit('persisted/SET_AUTH_REDIRECT_URI', null)
      },
    },
    width: {
      get() {
        return this.$store.state.showcase.drawerWidth
      },
    },
    navLinksArr() {
      return navLinks.filter((element, index) => {
        if (
          element.id === 'Reviews' &&
          !this.businessConfigurations.enableReviews
        )
          return false

        if (element.id === 'LNS' && !this.businessConfigurations.isLnsEnabled)
          return false

        return true
      })
    },

    ...mapGetters('notification', ['notificationcount']),
    ...mapGetters('customer', ['customer']),
    ...mapGetters('address', ['getAddressByCustomerAddressID']),
    ...mapGetters('persisted', ['authRedirectUri']),
    getRedirectUri() {
      let uri = ''
      if (this.isCordova) {
        uri = process.env.ANDROID_DEEP_LINK
      } else {
        uri = Object.keys(this.$route.query).length
          ? window.location.href
          : window.location.origin + window.location.pathname
      }
      return uri
    },
    getEAIVExternalURL() {
      return process.env.EAIV_ExternalURL
    },
  },
  data() {
    return {
      // navLinks: navLinks,
      myProfileUrl: process.env.MY_PROFILE_URL,
      notificationDrawer: false,
    }
  },
  components: {
    NotificationList,
  },
  mounted() {
    this.setHeaderHeight()
  },
  methods: {
    closeAccount() {
      this.notificationDrawer = false
      this.drawerState = false
    },
    navLinkClicked(navLink) {
      this.notificationDrawer = false
      if (navLink.external) this.openURL(navLink.link)
      if (this.customerExists) this.drawerState = false
    },
    openNotificationDrawer() {
      this.notificationDrawer = !this.notificationDrawer
    },
    closeNotificationDrawer() {
      this.notificationDrawer = false
    },
    async login(isSignup, signupAcr) {
      this.loginWithIdentity(isSignup, signupAcr)
      // this.drawerState = false
    },
    personalInfoObj() {
      let address = null,
        dob = '',
        personalInfo = {}
      if (this.isLoggedIn) {
        address = this.getAddressByCustomerAddressID(
          this.customer.defaultAddressID
        )

        dob = this.currentUser.dob
          ? this.$options.filters.formatDate(this.currentUser.dob, 'YYYY-MM-DD')
          : ''
        personalInfo = {
          firstName: this.currentUser.firstName || '',
          lastName: this.currentUser.lastName || '',
          dob: dob,
          addressLine1: address?.line1 || '',
          addressLine2: address?.line2 || '',
          city: address?.city || '',
          state: address?.state || '',
          zip: address?.zip || '',
          phone: this.currentUser.phoneNumber || '',
          email: this.currentUser.email || '',
          countryCode: this.currentUser.countryCode || '+1',
        }
      }
      return personalInfo
    },
    triggerEAIVFlow() {
      hideShowFullSpinner(true)
      let reqData = {
        collectPersonalInfo: true,
        preFillInformation: this.personalInfoObj(),
        redirectUri: this.getRedirectUri,
        verificationDuring: 0,
        customerID: null,
      }

      this.$store
        .dispatch('ageVerification/sendPersonalInfo', reqData)
        .then((response) => {
          if (response.success && response.data && response.data?.sessionId) {
            if (this.isCordova) {
              this.openURL(
                this.getEAIVExternalURL +
                  `?sessionId=${response.data.sessionId}`
              )
            } else if (!this.isCordova && 'FTXIdentityLite' in window) {
              let payloadObj = {
                sessionId: response.data.sessionId,
                onSuccess: this.identitySuccessHandler,
                onError: this.identityErrorHandler,
                styleOptions: {
                  height: `calc(100dvh - ${this.getHeaderHeight}px)`,
                  top: `${this.getHeaderHeight}px`,
                },
              }
              this.initEAIVPopup(payloadObj)
            }
          } else if (!response.success && response.message) {
            this.showError(response.message)
          }
        })
        .finally(() => {
          hideShowFullSpinner(false)
        })
    },
    openURL(url) {
      if (this.isCordova) {
        window.open(url, '_system')
      } else {
        window.location = url
      }
    },
    openAgeVerifiedQR() {
      hideShowFullSpinner(true)
      let reqData = {
        redirectUri: this.getRedirectUri,
        verificationDuring: 3,
      }

      this.$store
        .dispatch('ageVerification/sendPersonalInfo', reqData)
        .then((response) => {
          if (response.success && response.data && response.data?.sessionId) {
            if (this.isCordova) {
              this.openURL(
                this.getEAIVExternalURL +
                  `?sessionId=${response.data.sessionId}`
              )
            } else if (!this.isCordova && 'FTXIdentityLite' in window) {
              let payloadObj = {
                sessionId: response.data.sessionId,
                onSuccess: () => {},
                onError: () => {},
                styleOptions: {
                  height: `calc(100dvh - ${this.getHeaderHeight}px)`,
                  top: `${this.getHeaderHeight}px`,
                },
              }
              this.initEAIVPopup(payloadObj)
            }
          } else if (!response.success && response.message) {
            this.showError(response.message)
          }
        })
        .finally(() => {
          hideShowFullSpinner(false)
        })
    },
  },
}
</script>
<style lang="scss">
.sidebarAccount {
  .sidebarHeader {
    padding: 0 20px;
    font-size: 20px;
    font-family: 'Lato', sans-serif;
    color: #333b3e;
    font-weight: bold;
    position: relative;
    z-index: 1;
    .q-btn {
      position: absolute;
      top: 10px;
      right: 10px;
    }
    @media (min-width: 1681px) {
      font-size: 24px;
    }
    @media (max-width: 1023px) {
      font-size: 18px;
    }
  }
  .sidebarPanel {
    padding: 20px;
    h5 {
      margin: 0;
      padding: 15px 0;
      font-size: 20px;
      font-weight: bold;
      color: #333b3e;
    }
    p {
      font-size: 12px;
      line-height: 1.4;
      color: #888888;
    }
    @media (max-width: 374px) {
      padding: 20px 0 0;
      .form-action {
        .q-btn-lg {
          .q-btn__wrapper {
            font-size: 14px;
          }
        }
      }
    }
  }
}
.accountUserInfo {
  .q-item {
    padding: 30px 20px 20px;
    padding-right: 50px;
    position: initial;
    .btn-badge {
      padding: 4px;
      font-size: 12px;
      background: #ffedd9;
      color: #ff8d30;
      border: 1px solid #ffdcb5;
      border-radius: 20px;
      &.badge-verified {
        background: #e1ffe1;
        color: #3eae5b;
        border-color: #a6e9a7;
      }
    }
    &.butttons {
      padding: 0 16px 24px;
      border-bottom: 1px solid rgba(112, 112, 112, 0.25);
      .q-btn {
        .q-btn__wrapper {
          padding-top: 10px;
          padding-bottom: 10px;
        }
      }
      .notification-btn-count {
        position: absolute;
        right: 2px;
        top: 6px;
        border-radius: 50%;
      }
    }
    @media (min-width: 1681px) {
      padding: 30px 20px;
      padding-bottom: 20px;
    }
    @media (max-width: 374px) {
      padding-right: 40px;
    }
  }
  .q-item__section--avatar {
    justify-content: flex-start;
    min-width: 66px;
    padding-right: 10px;
    .q-avatar {
      width: 66px;
      height: 66px;
      font-size: 36px !important;
    }
    @media (min-width: 1681px) {
      min-width: 72px;
      padding-right: 15px;
      .q-avatar {
        width: 72px;
        height: 72px;
      }
    }
  }
  .text-h5 {
    margin-bottom: 8px;
    font-size: 16px;
    font-family: 'Lato', sans-serif;
    font-weight: bold;
    color: #333b3e;
    @media (min-width: 1681px) {
      font-size: 20px;
    }
  }
  .text-caption {
    margin: 0;
    margin-bottom: 8px;
    font-size: 13px;
    color: #888888;
    @media (min-width: 1681px) {
      font-size: 16px;
    }
  }
  .q-btn {
    font-size: 12px;
    font-weight: bold;
    @media (min-width: 1281px) {
      font-weight: normal;
    }
    @media (min-width: 1681px) {
      font-size: 14px;
    }
  }
}

.accountQuickLinks {
  .q-list {
    padding: 20px 15px;
    @media (max-width: 1023px) {
      padding: 15px 10px;
    }
  }
  .q-item {
    padding: 10px;
    @media (min-width: 1681px) {
      padding: 15px;
    }
    .q-icon {
      padding: 10px;
      border-radius: 5px;
      border: 2px solid $secondaryOnBody;
      border: 2px solid var(--q-color-secondaryOnBody);
      color: #314154;
      transition: all 0.3s ease-in-out;
      @media (min-width: 1681px) {
        padding: 15px;
      }
      svg {
        width: 14px;
        height: 14px;
        @media (min-width: 1681px) {
          width: 24px;
          height: 24px;
        }
      }
      &.account-autoship {
        padding: 5px;
        font-size: 26px !important;
        svg {
          width: 24px;
          height: 24px;
        }
        @media (min-width: 1681px) {
          font-size: 36px !important;
        }
      }
    }
    .title {
      font-size: 13px;
      font-weight: bold;
      color: #314154;
      @media (min-width: 1681px) {
        font-size: 17px;
      }
    }
    .text-caption {
      font-size: 12px;
      color: #888888;
      @media (min-width: 1681px) {
        font-size: 14px;
      }
    }
    @media (min-width: 1024px) {
      &:hover {
        .q-icon {
          border-color: $secondary;
          border-color: var(--q-color-secondaryOnBody) !important;
          color: $white;
          color: var(--q-color-secondaryOnBody) !important;
        }
        .title {
          color: $secondary;
          color: var(--q-color-secondaryOnBody) !important;
        }
      }
    }
  }
}
.accountLoggedHeight {
  height: calc(100dvh - 260px);
  @media (min-width: 1024px) {
    height: calc(100dvh - 342px);
  }
  &.accountAgeVerifiedHeight {
    height: calc(100dvh - 294px);
    @media (min-width: 1024px) {
      height: calc(100dvh - 378px);
    }
  }
}

.accountLoginHeight {
  margin-top: 50px;
  height: calc(100vh - 90px);
  @media (min-width: 1024px) {
    height: calc(100vh - 170px);
  }
}
.hasLocationHeader {
  .accountLoginHeight {
    height: calc(100vh - 84px);
    @media (min-width: 1024px) {
      height: calc(100vh - 170px);
    }
  }
  .accountLoggedHeight {
    height: 73vh;
    @media (min-width: 1024px) {
      height: calc(100vh - 280px);
    }
  }
}
.accountCreateHeight {
  form {
    padding-right: 20px;
    .q-field__control,
    .q-field__marginal {
      height: 48px;
    }
    .q-field__label {
      top: 13px;
    }
  }
  height: calc(100vh - 104px);
  @media (min-width: 1024px) {
    height: calc(100vh - 225px);
  }
}

// notification drawer
.notification-lists {
  position: absolute;
  right: 100%;
  top: 10px;
  width: 400px;
  background-color: #fff;
  border-right: 1px solid rgba(0, 0, 0, 0.12);
  opacity: 0;
  transition: all 0.2s ease;
  border-radius: 0px;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;

  @media (max-width: 1024px) {
    width: 100%;
    opacity: 1;
    display: none;
    position: fixed;
    min-width: 100%;
    left: auto;
    right: 0;
    top: 0;
    bottom: 0;
    min-height: 100vh;
    z-index: 99999;
    border-right: none;
  }
  .notification-item {
    align-items: center;
    padding: 16px 20px;
    position: relative;
    cursor: default;
    pointer-events: none;
    &:last-child {
      border-bottom: none;
    }
  }
  .text-caption {
    margin-bottom: 0;
    font-size: 12px;
    margin-top: 5px;
  }
  .q-item__section--avatar {
    min-width: 30px;
  }

  &.show-notification {
    opacity: 1;
    .notification-item {
      cursor: inherit;
      pointer-events: inherit;
    }
    @media (max-width: 1023px) {
      opacity: 1;
      display: block;
    }
  }
}

.notification-btn-count {
  width: 20px;
  height: 20px;
  font-size: 10px;
  line-height: 20px;
  background-color: #fff;
  color: var(--q-color-primary);
  margin-right: 5px;
  border-radius: 4px;
}
</style>
