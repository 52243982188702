<template>
  <q-card
    flat
    :class="['notification-lists', { 'show-notification': notificationDrawer }]"
  >
    <q-card-section
      class="flex items-center q-py-sm notification-header"
    >
      <span class="text-tertiary text-weight-bold">Notification</span>
      <q-btn
        dense
        flat
        color="tertiary"
        @click="$emit('openNotificationDrawer')"
        class="q-ml-auto"
      >
        <q-icon name="close" />
      </q-btn>
    </q-card-section>
    <q-separator />
    <q-card-section class="notification-main" :class="notificationcount != 0 ? '' : 'empty-notification'">
      <q-scroll-area
        :thumb-style="scrollAreaThumbStyle"
        :bar-style="scrollAreaBarStyle"
        :visible="scrollAreaVisible"
        class="notification-scroll"
        :style="
          isMobile
            ? 'height:calc(100vh - 122px); padding-right:15px;'
            : 'height:400px; padding-right:15px;'
        "
      >
        <div
          v-if="loading"
          class="flex items-center justify-center"
          :style="isMobile ? 'height:calc(100vh - 122px);' : 'height:400px;'"
        >
          <q-spinner color="primary" size="3em" :thickness="2" />
        </div>
        <template v-else>
          <q-list v-if="notifications.length">
            <NotificationCard
              v-for="notification in notifications"
              :key="notification.notificationID"
              :notification="notification"
            />
          </q-list>
          <div
            v-else
            class="notification-placeholder"
            :style="isMobile ? 'height:calc(100vh - 122px);' : 'height:350px; '"
          >
            <img
              src="images/empty-notification.png"
              width="72"
              height="72"
              alt=""
            />
            <div class="text-subtitle2 text-grey-5">No updates yet! <br />Keep shopping for amazing deals.</div>
          </div>
        </template>
      </q-scroll-area>
    </q-card-section>
    <q-separator />
    <q-card-section class="q-py-md text-right" v-if="notificationcount != 0">
      <q-btn
        unelevated
        no-caps
        outline
        color="tertiary"
        class="notification-clear-btn"
        @click="clearAllNotification"
        >Clear All
      </q-btn>
    </q-card-section>
  </q-card>

</template>

<script>
import { mapGetters } from 'vuex';
import NotificationCard from './NotificationCard.vue';
export default {
  name: 'NotificationList',
  props: {
    notificationDrawer: {
      type: Boolean,
    },
  },
  computed: {
    ...mapGetters('notification', [
      'notifications',
      'notificationcount',
      'loading',
    ]),
  },

  components: {
    NotificationCard,
  },
  methods: {
    clearAllNotification() {
      this.$store.dispatch('notification/removeAllNotification')
    },
  },
}
</script>

<style lang="scss">
.notification-placeholder {
  min-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.notification-scroll {
  max-height: calc(100vh - 300px);
  .scroll {
    .absolute.full-width {
      min-height: 100%;
    }
  }
  @media (max-width: 1023px) {
    max-height: calc(100dvh - 120px);
  }
}
.notification-main {
  background: #F8F8F8;
  padding: 0;
  &.empty-notification {
    border-bottom-left-radius: 10px!important;
  }
}
</style>
